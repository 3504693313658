import { useCallback } from "react";
import "./FirstFeature.css";

const FirstFeature = () => {
  const onBookACallButtonClick = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='contactUsContainer']"
    );
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onLearnMoreButtonClick = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='EmailContainer']"
    );
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  return (
    <div className="first-feature">
    <div className="first-feature-child">
    <img
    src="./firstfeature.png"
    alt="Background Image"
    style={{
      objectFit: "cover",
      objectPosition: "center",
      width: "100%",
      height: "100%",
      borderRadius: "var(--br-21xl)",
    }}
  />
      </div>
      <div className="optimize-operations-direct">
        Optimize Operations, Direct Feedback.
      </div>
      <div className="streamline-email-workflows">{`Streamline email workflows for optimal sales impact. From cart recovery to client re-engagement and welcoming new customers, our team ensures efficiency and results. `}</div>
      <button className="book-a-call-button" onClick={onBookACallButtonClick}>
        <div className="book-a-call2">Book a call</div>
      </button>
      <button className="learn-more-button" onClick={onLearnMoreButtonClick}>
        <div className="learn-more">Learn more</div>
      </button>
    </div>
  );
};

export default FirstFeature;
