import "./MobileContact.css";

const MobileContact = () => {
  return (
    <div className="mobile-contact" style ={{marginTop : "-40px"}}>
      <div className="contact-us2" >Contact Us</div>
      <form action="https://api.web3forms.com/submit" method="POST">
      <input type="hidden" name="access_key" value={process.env.REACT_APP_ACCESSKEY}/>
      <div className="contact-us3">
        <div className="contact-us-child" />
        <input
              className="mobile-firstname-child"
              name="Firstname"
              type="text"
              required
            />
        <input
              className="mobile-lastname-child"
              name="Lastname"
              type="text"
              required
            />
        <input className="mobile-email-child" name="email" type="email" required style={{marginTop : "5px"}}/>
        <div className="first-name1">First Name</div>
        <div className="email3">Email</div>
        <div className = "text-wrapper">
        <div className="tell-us-more1" style={{marginTop : "10px"}}>Tell us more about you</div>
        <textarea
              className="mobile-content-child"
              name="content"
              type="text"
              style={{marginTop : "10px"}}
              required
            />
        </div>
        <div className="last-name1">Last Name</div>
        <button className="submit-form-button1">
          <div className="submit1">Submit</div>
        </button>
      </div>
      </form>
      <div
        className="anchor-mobile-contact"
        data-scroll-to="mobileContactContainer"
      /> 
    </div>
  );
};

export default MobileContact;
