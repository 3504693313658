import TestimonialCard from "./TestimonialCard";
import TestimonialCarousel from "./TestimonialCarousel.js"
import "./Testimonials.css";

const Testimonials = () => {
  return (
    <div className="testimonials2">
      <div className="testimonials3">Testimonials</div>
      <div className="frame-div">
        <TestimonialCarousel 
        />
      </div>
      <div
        className="anchor-testimonials"
        data-scroll-to="testimonialsContainer"
      />
    </div>
  );
};

export default Testimonials;
