import React, {useState, useEffect} from "react";
import "./MobileAccordian.css";


const MobileAccordian = () => {

  const [mcard1open, setmcard1Open] = useState(false);
  const [mcard2open, setmcard2Open] = useState(false);
  const [mcard3open, setmcard3Open] = useState(false);
  const [cardCount, setcardCount] = useState(0);

  async function handleonClick(e){
    e.preventDefault();
    const cardValue = e.target.dataset.card;
    if(cardValue === "mcard1"){
      setmcard1Open(!mcard1open)
    }
    else if(cardValue === "mcard2"){
      setmcard2Open(!mcard2open) 
    }
    else if(cardValue === "mcard3"){
      setmcard3Open(!mcard3open) 
    }
  }

  async function getCard() {
    let count = 0;
  
    if (mcard3open) {
      count++;
    }
  
    if (mcard2open) {
      count++;
    }
  
    if (mcard1open) {
      count++;
    }
  
    return count;
  }

  useEffect( () => {
    const getCardCount = async () => {

      const count = await getCard();

      setcardCount(count);
    }

    getCardCount();
  }, [mcard1open, mcard2open, mcard3open])

  const componentStyle = {
    marginBottom: `${
      cardCount === 3 ? "50px" :
      cardCount === 2 ? "-100px" :
      cardCount === 1 ? "-200px" :
      "-400px"
    }`
  };

  return (
    <div className = "mobile-hide-accordian">
 <div className="mobile-accordian" style ={componentStyle}>
      <div className="what-is-flowxcl1">What is Flowxcl?</div>
      <div className = "mobile-accordian-card">
        <div className = "mobile-card-1 mobile-card">
        <div className = "mtitle">
            <h5>Our Mission</h5>
            <img className={`expand-button ${mcard1open ? "rotate-button" : "unrotate-button" }`} src = "/button-arrow-expand-1-64.png" data-card="mcard1" onClick={handleonClick}/>
        </div>
          <div className = {`content ${mcard1open ? "show" : "hide"}`}>
          <hr className = "custom-hr"/>
          <p className = "mobile-card-description">
            Our mission is to create strong, comprehensive systems for your business, and provide detailed analytics to elevate your busiensses email games.
          </p>
        </div>
      </div>
      <div className = "mobile-card-2 mobile-card">
        <div className = "mtitle">
            <h5>Our Technology</h5>
            <img className={`expand-button ${mcard2open ? "rotate-button" : "unrotate-button" }`} src = "/button-arrow-expand-1-64.png" data-card="mcard2" onClick={handleonClick}/>
        </div>
          <div className = {`content ${mcard2open ? "show" : "hide"}`}>
          <hr className = "custom-hr"/>
          <p className = "mobile-card-description">
            Leveraging technology and harnessing the power of AI, our services maximise your brands ability to retain a strong and loyal customer base.
          </p>
        </div>
      </div>
      <div className = "mobile-card-3 mobile-card">
        <div className = "mtitle">
            <h5>Our Vision</h5>
            <img className={`expand-button ${mcard3open ? "rotate-button" : "unrotate-button" }`} src = "/button-arrow-expand-1-64.png" data-card="mcard3" onClick={handleonClick}/>
        </div>
          <div className = {`content ${mcard3open ? "show" : "hide"}`}>
          <hr className = "custom-hr"/>
          <p className = "mobile-card-description">
          As representatives of the modern generation. We firmly believe that our expertise can be strategically 
          leveraged to contribute positively to the growth and success of your business.
          </p>
        </div>
      </div>
      </div>
    </div>
    <div data-scroll-to="mobileAccordian"></div>
    </div>

  );
};

export default MobileAccordian;
