import "./MobileNavbar.css";
import MobileNavigationMenu from "./MobileNavigationMenu";
import React, {useState} from "react";

const MobileNavbar = () => {

  const [isOpen,  setIsOpen] = useState(false);

  async function handleonClick(){
    setIsOpen(!isOpen);
  }
  
  return (
    <div>
  <div className="mobile-navbar">
    <div className="navbar-content">
      <div className="mobile-navbar-child" style = {{
        backgroundImage : "url(mobilelogo.png)",
        backgroundSize : "cover",
        backgroundPosition : "50% 50%",
      }} />
      <h2 className = "company-name">FlowXCL</h2>
      <img
        className={`hamburger-menu-icon ${isOpen ? "open-hamburger" : ""}`}
        alt=""
        src={isOpen ? "/x-symbol-svgrepo-com.svg"  : "/hamburger-menu.svg"}
        onClick={handleonClick}
      />
      </div>
     </div>  
     {isOpen 
     ? 
     <div className = "navigation-menu">
      <MobileNavigationMenu />    
     </div>:
     <></> }
    </div>
  );
};

export default MobileNavbar;
