import Promotion from "./Promotion";
import "./Email.css";

const Email = () => {
  return (
    <div className="email">
    <div>
    <div className="why-email-marketing">Why Email Marketing?</div>
      <div className="rectangle-wrapper">
      <div className="rectangle-group">
      <Promotion
          rectangleDivLeft="unset"
          rectangleDivTop="unset"
          rectangleDivWidth="201px"
          rectangleDivHeight="182.7px"
          rectangleDivBottom="unset"
          backgroundImage="./email.png"
          backgroundPosition="50% 10%"
        />
        <Promotion
          rectangleDivLeft="unset"
          rectangleDivTop="unset"
          rectangleDivWidth="201px"
          rectangleDivHeight="182.7px"
          rectangleDivBottom="unset"
          backgroundImage="./phone.png"
          backgroundPosition="10%"
        />
        <Promotion
          rectangleDivLeft="unset"
          rectangleDivTop="unset"
          rectangleDivWidth="201px"
          rectangleDivHeight="182.7px"
          rectangleDivBottom="unset"
          backgroundImage="./customerretention.webp"
        />
        <Promotion
          rectangleDivLeft="unset"
          rectangleDivTop="unset"
          rectangleDivWidth="201px"
          rectangleDivHeight="182.7px"
          rectangleDivBottom="unset"
          backgroundImage="./internet.jpg"
          backgroundPosition="10%"
        />
      </div>
      </div>
      <div className = "description">
      <div>
      WITH 4 BILLION DAILY USERS WORLDWIDE, EMAIL MARKETING MAXIMISES YOUR BRANDS ABILITY TO CREATE A LOYAL FOLLOWING TO YOUR BUSINESS. WHETHER YOU’RE RETARGETING CUSTOMERS THROUGH CAMPAIGNS, SENDING WIN-BACK EMAILS TO CUSTOMERS WHO ABANDONED CART, THE ABILITY TO TAKE YOUR BUSINESS TO THE NEXT STEP IS ENDLESS WITH EMAIL MARKETING.
      </div>
      <div>
      ACCORDING TO STATISTA.COM, EMAIL REVENUE WORLDWIDE IS EXPECTED TO GROW FROM $10.89 BILLION IN 2023 TO $17.90 BILLION IN 2027 - IN US EXCHANGE. THE AVERAGE EMAIL OPEN RATE IS BETWEEN 46 - 50%, WITH AN AVERAGE CLICKTHROUGH RATE OF 2.6 - 3%. DESPITE LOW CONVERSIONS AT FIRST GLANCE, THE ABILITY TO SEND EMAILS TO A BULK LIST OFTEN PROVES TO YIELD MORE ROI THAN THAT OF OTHER MARKETING PLATFORMS.
      </div>
      </div>
    </div>
      

      <div data-scroll-to="EmailContainer"></div>
    </div>
  );
};

export default Email;
