import "./SecondFeature.css";

const SecondFeature = () => {
  return (
    <div className="second-feature">
      <div className="second-feature-child" style = {
        {backgroundImage : "url(automate.png)",
         backgroundSize : "cover",
         backgroundPosition : "50% 10%"}
      }/>
      <div className="automated-workflows-parent">
        <div className="automated-workflows">
          <div className="advanced-anaytics">Automated workflows</div>
          <div className="efficiently-streamline-email">
            Efficiently streamline email tasks with automated workflows by
            Flowxcl. From abandoned carts to client engagement, our system
            ensures a structured and professional approach.
          </div>
        </div>
        <div className="email-campaign">
          <div className="email-campaign-wrapper">
            <div className="email-campaign1">Email Campaign</div>
          </div>
          <div className="elevate-product-visibility">
            Elevate product visibility with precision-targeted email campaigns.
            Our strategic collaboration ensures the creation of robust and
            dependable campaigns.
          </div>
        </div>
        <div className="advanced-analytics">
          <div className="advanced-anaytics">Advanced anaytics</div>
          <div className="efficiently-streamline-email">
            Leverage advanced analytics to meticulously track growth and uncover
            improvement opportunities across diverse industries.
          </div>
        </div>
      </div>
      <div className="an-old-internet">
        “An old internet Art, With a modern twist”
      </div>
      <div className="automated-workflows-email-container">
        <p className="automated-workflows-email">
          Automated Workflows, Email Campaign and Advanced Analytics
        </p>
      </div>
      <div
        className="anchor-second-feature"
        data-scroll-to="secondFeatureContainer"
      />
    </div>
  );
};

export default SecondFeature;
