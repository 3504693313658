import DesktopNavBar from "../components/DesktopNavBar";
import MobileNavbar from "../components/MobileNavbar";
import FirstFeature from "../components/FirstFeature";
import MobileFirstFeature from "../components/MobileFirstFeature";
import Email from "../components/Email";
import MobileEmail from "../components/MobileEmail";
import SecondFeature from "../components/SecondFeature";
import MobileSecondFeature from "../components/MobileSecondFeature";
import Accordian from "../components/Accordian";
import MobileAccordian from "../components/MobileAccordian";
import Testimonials from "../components/Testimonials";
import MobileTestimonial from "../components/MobileTestimonial";
import ContactUs from "../components/ContactUs";
import MobileContact from "../components/MobileContact";
import DesktopFooter from "../components/DesktopFooter";
import "./Desktop.css";

const Desktop = () => {
  return (
    <div className="desktop-1">
      <DesktopNavBar />
      <MobileNavbar />
      <FirstFeature />
      <MobileFirstFeature />
      <Email />
      <MobileEmail />
      <SecondFeature />
      <MobileSecondFeature />
      <Accordian />
      <MobileAccordian />
      <Testimonials />
      <MobileTestimonial />
      <ContactUs />
      <MobileContact />
      <DesktopFooter />
    </div>
  );
};

export default Desktop;
