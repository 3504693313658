import { useCallback } from "react";
import "./MobileNavigationMenu.css";

const MobileNavigationMenu = () => {
  const onOurServiceTextClick = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='mobileSecondFeature']"
    );
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onAboutUsTextClick = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='mobileAccordian']"
    );
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onTestimonialsTextClick = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='mobiletestimonialsContainer']"
    );
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behaviour : "smooth" });
    }
  }, []);

  const onBookACallClick = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='mobileContactContainer']"
    );
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  return (
    <div className="mobile-navigation-menu">
      <div className="our-service-parent">
        <div className="our-service1" onClick={onOurServiceTextClick}>
          Our Service
        </div>
        <div
          className="our-service1"
          onClick={onAboutUsTextClick}
        >{`About Us `}</div>
        <div className="our-service1" onClick={onTestimonialsTextClick}>
          Testimonials
        </div>
        <div className="our-service1" onClick={onBookACallClick}>
          Book a Call
        </div>
      </div>
    </div>
  );
};

export default MobileNavigationMenu;
