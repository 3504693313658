import React, {useState, useEffect} from 'react'
import "./Accordian.css";

const Accordian = () => {

  const [card1open, setcard1Open] = useState(false);
  const [card2open, setcard2Open] = useState(false);
  const [card3open, setcard3Open] = useState(false);
  const [cardCount, setcardCount] = useState(0);

  async function handleonClick(e){
    e.preventDefault();
    const cardValue = e.target.dataset.card;
    if(cardValue === "card1"){
      setcard1Open(!card1open)
    }
    else if(cardValue === "card2"){
      setcard2Open(!card2open) 
    }
    else if(cardValue === "card3"){
      setcard3Open(!card3open) 
    }
  }

  async function getCard() {
    let count = 0;
  
    if (card3open) {
      count++;
    }
  
    if (card2open) {
      count++;
    }
  
    if (card1open) {
      count++;
    }
  
    return count;
  }

  useEffect( () => {
    const getCardCount = async () => {

      const count = await getCard();

      setcardCount(count);
    }

    getCardCount();
  }, [card1open, card2open, card3open])

  const componentStyle = {
    marginBottom: `${
      cardCount === 3 ? "100px" :
      cardCount === 2 ? "-100px" :
      cardCount === 1 ? "-200px" :
      "-300px"
    }`
  };

  
  return (
    <div className="accordian" style = {componentStyle}>
      <div className="what-is-flowxcl">What is Flowxcl?</div>
      <div className = "accordian-card">
        <div className = "card-1 card">
        <div className = "title">
            <h5>Our Mission</h5>
            <img className={`expand-button ${card1open ? "rotate-button" : "unrotate-button" }`} src = "./button-arrow-expand-1-64.png" data-card="card1" onClick={handleonClick}/>
        </div>
          <div className = {`content ${card1open ? "show" : "hide"}`}>
          <hr className = "custom-hr"/>
          <p className = "card-description">
            Our Mission is to create strong, comprehensive systems for your business, and provide detailed analytics to elevate your businesses email game. In this new age,
            customers are getting ever so impatient, they are seeing hundreds if not thousands of brands each day, and the environment is more competitve than ever. Our multi-step
            processes ensure that sales are amplified - as often, and more exposure means more chance of purchase.
          </p>
        </div>
      </div>
      <div className = "card-2 card">
        <div className = "title">
            <h5>Our Technology</h5>
            <img className={`expand-button ${card2open ? "rotate-button" : "unrotate-button" }`} src = "./button-arrow-expand-1-64.png" data-card="card2" onClick={handleonClick}/>
        </div>
          <div className = {`content ${card2open ? "show" : "hide"}`}>
          <hr className = "custom-hr"/>
          <p className = "card-description">
            Leveraging technology and harnessing the power of AI, our services maximise your brands ability to retain a strong and loyal customer base. Our multi-step processes
            ensures email sales sytems are optimised. Analytics are essential to tracking the growth of any busienss, with our team providing directed reports and powerful strategies
            to further accelerate growth.
          </p>
        </div>
      </div>
      <div className = "card-3 card">
        <div className = "title">
            <h5>Our Vision</h5>
            <img className={`expand-button ${card3open ? "rotate-button" : "unrotate-button" }`} src = "./button-arrow-expand-1-64.png" data-card="card3" onClick={handleonClick}/>
        </div>
          <div className = {`content ${card3open ? "show" : "hide"}`}>
          <hr className = "custom-hr"/>
          <p className = "card-description">
            As representatives of the modern generation, we know what sticks and what doesn't. We have grown up in an era full of organic marketing, and We firmly believe that our expertise can be strategically 
            leveraged to contribute positively to the growth and success of your business.
          </p>
        </div>
      </div>
      </div>
      <div className="anchor-accordian" data-scroll-to="accordianContainer" />
    </div>
  );
};

export default Accordian;
