import "./DesktopFooter.css";
import React from 'react';

const DesktopFooter = () => {
  return (
    <div className = "wrapper">
      <footer className="bottom text-center text-lg-start footer-container">
        <section className="d-flex justify-content-between p-4 footer-section">
          <div className="me-5 get-connected-div">
            <span>Get connected with us on social networks:</span>
          </div>
          <div>
            <a href="https://www.instagram.com/flowxcl?igsh=Z24xZ2cydnBzb3l4" className=" me-4" target="_blank">
              <i className="fab fa-instagram icon"></i>
            </a>
            <a href="https://www.linkedin.com/company/flowxcl/about/" className=" me-4" target="_blank">
              <i className="fab fa-linkedin icon"></i>
            </a>
          </div>
        </section>
       

        <section className="footer-content">
          <div className=" text-center text-md-start mt-5">
            <div className="row mt-3">
              <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4" style = {{display : "flex", flexDirection : "column"}}>
                <h6 className="text-uppercase fw-bold">FlowXCL</h6>
                <div className = "hr-wrapper">
                <hr className="mb-4 mt-0 d-inline-block" style={{ width: '60px', height: '2px', justifyContent : "center" }} />  
                </div>
                <div style={{display : "flex", justifyContent:"center"}}>
                <img src = "./Flowxcllogo2.png" style = {{height : "200px", width : "200px", marginTop : "-50px"}}/>
                </div>
              </div>

              <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold">Useful links</h6>
                <hr className="mb-4 mt-0 d-inline-block mx-auto" style={{ width: '60px', height: '2px' }} />
                <p><a href="#!" className="">Your Account</a></p>
                <p><a href="#!" className="">Terms and Conditions</a></p>
                <p><a href="#!" className="">Shipping Rates</a></p>
                <p><a href="#!" className="">Help</a></p>
              </div>

              <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                <h6 className="text-uppercase fw-bold">Contact</h6>
                <hr className="mb-4 mt-0 d-inline-block mx-auto" style={{ width: '60px', height: '2px' }} />
                <p><i className="fas fa-home mr-3"></i> Brisbane, Queensland Australia</p>
                <p><i className="fas fa-envelope mr-3"></i> info@flowxcl.com.au</p>
                <p><i className="fas fa-phone mr-3"></i> 0498 488 239</p>
              </div>
            </div>
          </div>
        </section>

        <div className="text-center p-3" style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
          © 2024 Copyright:
          <a className="" href="https://flowxcl.com.au/"> FlowXCL Pty Ltd</a>
        </div>
      </footer>
    </div>
    
  );
};

export default DesktopFooter;
