import { useCallback } from "react";
import "./MobileFirstFeature.css";

const MobileFirstFeature = () => {
  const onBookACallButtonClick = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='mobileContactContainer']"
    );
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior : "smooth" });
    }
  }, []);

  const onLearnMoreButtonClick = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='mobileEmailContainer']"
    );
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior : "smooth"});
    }
  }, []);

  return (
    <div className="mobile-first-feature">
      <div className="optimize-operations-direct1">
        Optimize Operations, Direct Feedback.
      </div>
      <div className="streamline-email-workflows1">
        Streamline email workflows for optimal sales impact. From cart recovery
        to client re-engagement and welcoming new customers, our team ensures
        efficiency and results.
      </div>
      <div className="book-a-call-button1" onClick={onBookACallButtonClick}>
        <div className="book-a-call-button-child" />
        <div className="book-a-call3">book a call</div>
      </div>
      <div className="learn-more-button1">
        <div className="learn-more-button-child" onClick={onLearnMoreButtonClick} />
        <div className="learn-more1">Learn more</div>
      </div>
      <div className="mobile-first-feature-child" style={{
        backgroundImage : `url(firstfeature.png)`,
        backgroundSize : "cover",
        backgroundPosition : "40%"
      }} />
    </div>
  );
};

export default MobileFirstFeature;
