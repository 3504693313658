import { useMemo } from "react";
import "./Promotion.css";

const Promotion = ({
  rectangleDivLeft,
  rectangleDivTop,
  rectangleDivWidth,
  rectangleDivHeight,
  rectangleDivBottom,
  backgroundImage,
  backgroundPosition,
}) => {
  const rectangleDivStyle = useMemo(() => {
    return {
      left: rectangleDivLeft,
      top: rectangleDivTop,
      width: rectangleDivWidth,
      height: rectangleDivHeight,
      bottom: rectangleDivBottom,
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: "cover", // You can use "contain" instead if you prefer
      backgroundPosition : backgroundPosition
    };
  }, [
    rectangleDivLeft,
    rectangleDivTop,
    rectangleDivWidth,
    rectangleDivHeight,
    rectangleDivBottom,
    backgroundImage, // Include backgroundImage in the dependency array
  ]);

  return <div className="frame-item" style={rectangleDivStyle} />;
};

export default Promotion;
