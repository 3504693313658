import Promotion from "./Promotion";
import "./MobileEmail.css";

const MobileEmail = () => {
  return (
    <div className="mobile-email">
      <div className="why-email-marketing-parent">
        <div className="why-email-marketing1">Why Email Marketing?</div>
        <div className="rectangle-container">
          <Promotion
            rectangleDivLeft="calc(50% - 196.1px)"
            rectangleDivTop="unset"
            rectangleDivWidth="182.4px"
            rectangleDivHeight="145.3px"
            rectangleDivBottom="4.7px"
            backgroundImage="./customerretention.webp"
          />
          <Promotion
            rectangleDivLeft="calc(50% - 196.1px)"
            rectangleDivTop="unset"
            rectangleDivWidth="182.4px"
            rectangleDivHeight="145.3px"
            rectangleDivBottom="4.7px"
            backgroundImage="./internet.jpg"
          />
        </div>
        <div className = "mobileEmailDescription">
        <div>
        WITH 4 BILLION DAILY USERS WORLDWIDE, EMAIL MARKETING MAXIMISES YOUR BRANDS ABILITY TO CREATE A LOYAL FOLLOWING TO YOUR BUSINESS. WHETHER YOU’RE RETARGETING CUSTOMERS THROUGH CAMPAIGNS, SENDING WIN-BACK EMAILS TO CUSTOMERS WHO ABANDONED CART, THE ABILITY TO TAKE YOUR BUSINESS TO THE NEXT STEP IS ENDLESS WITH EMAIL MARKETING.
        </div>
        <div>
        ACCORDING TO STATISTA.COM, EMAIL REVENUE WORLDWIDE IS EXPECTED TO GROW FROM $10.89 BILLION IN 2023 TO $17.90 BILLION IN 2027 - IN US EXCHANGE. THE AVERAGE EMAIL OPEN RATE IS BETWEEN 46 - 50%, WITH AN AVERAGE CLICKTHROUGH RATE OF 2.6 - 3%. DESPITE LOW CONVERSIONS AT FIRST GLANCE, THE ABILITY TO SEND EMAILS TO A BULK LIST OFTEN PROVES TO YIELD MORE ROI THAN THAT OF OTHER MARKETING PLATFORMS.
        </div>
        </div>
      </div>
      <div
        className="anchor-mobile-email"
        data-scroll-to="mobileEmailContainer"
      />
    </div>
  );
};

export default MobileEmail;
