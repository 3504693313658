import "./MobileSecondFeature.css";

const MobileSecondFeature = () => {
  return (
    <div className="mobile-second-feature">
      <div className="automated-workflows-email-container1">
        <p className="automated-workflows-email1">
          Automated Workflows, Email Campaign and Advanced Analytics
        </p>
      </div>
      <div className="mobile-second-feature-child" style = {
        {backgroundImage : "url(automate.png)",
         backgroundSize : "cover",
         backgroundPosition : "50% 10%"}
      }/>
      <div className="email-campaign-parent">
        <div className="email-campaign2">Email Campaign</div>
        <div className="elevate-product-visibility1">
          Elevate product visibility with precision-targeted email campaigns.
          Our strategic collaboration ensures the creation of robust and
          dependable campaigns.
        </div>
        <div className="email-campaign2">Automated workflows</div>
        <div className="email-campaign3">
          <div className="efficiently-streamline-email1">
            Efficiently streamline email tasks with automated workflows by
            Flowxcl. From abandoned carts to client engagement, our system
            ensures a structured and professional approach.
          </div>
        </div>
        <div className="email-campaign2">Advanced anaytics</div>
        <div className="email-campaign4">
          <div className="leverage-advanced-analytics1">
            Leverage advanced analytics to meticulously track growth and uncover
            improvement opportunities across diverse industries.
          </div>
        </div>
      </div>
      <i className="an-old-internet1">
        “An old internet Art, With a modern twist”
      </i>
      <div
        className="anchor-mobile-second-feature"
        data-scroll-to="mobileSecondFeature"
      />
    </div>
  );
};

export default MobileSecondFeature;
