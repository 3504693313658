import "./MobileTestimonial.css";
import TestimonialCarousel from "./TestimonialCarousel.js"

const MobileTestimonial = () => {
  return (
    <div className ="mobile">
    <div data-scroll-to="mobiletestimonialsContainer"></div>
    <div className = "mobile-testimonial">
      <div className = "testimonials5">
      Testimonials
      </div>
      <div className = "mobilecarousel">
        <TestimonialCarousel />
      </div>
    </div>
    </div>
  );
};

export default MobileTestimonial;
