import { useCallback } from "react";
import "./DesktopNavBar.css";

const DesktopNavBar = () => {
  const onOurServiceTextClick = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='secondFeatureContainer']"
    );
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onAboutUsTextClick = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='accordianContainer']"
    );
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onTestimonialsTextClick = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='testimonialsContainer']"
    );
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onBookACallClick = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='contactUsContainer']"
    );
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  return (
    <div className="rectangle-parent">
      <div className="frame-child" style ={{
        backgroundImage : "url(Flowxcllogo.png)",
        backgroundSize : "cover",
        backgroundPosition : "50% 50%",
      }} />
      <div className="links">
        <div className="our-service" onClick={onOurServiceTextClick}>
          Our Service
        </div>
        <div className="about-us" onClick={onAboutUsTextClick}>
          About Us
        </div>
        <div className="about-us" onClick={onTestimonialsTextClick}>
          Testimonials
        </div>
        <div className="our-service" onClick={onBookACallClick}>
          Book a Call
        </div>
      </div>
    </div>
  );
};

export default DesktopNavBar;
