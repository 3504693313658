import React from 'react';
import {Carousel} from 'react-bootstrap';
import "./TestimonialCarousel.css"

const TestimonialCarousel = (
) => {
    return (
        <div>
          <Carousel>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="./testimonial1.png"
                alt="First slide"
                style = {{transform : `scale(0.6)`, marginTop : "50px"}}
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="./testimonial2.png"
                alt="Second slide"
                style = {{transform : `scale(0.6)`, marginTop : "50px"}}
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="./testimonial3.png"
                alt="First slide"
                style = {{transform : `scale(0.6)`, marginTop : "50px"}}
              />
            </Carousel.Item>
          </Carousel>
        </div>
      );
    };

export default TestimonialCarousel;
