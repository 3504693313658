import "./ContactUs.css";

const ContactUs = () => {

  return (
    <div className="contact-us">
      <div className="contact-us1">Contact Us</div>
      <form action="https://api.web3forms.com/submit" method="POST">
      <input type="hidden" name="access_key" value={process.env.REACT_APP_ACCESSKEY}/>
      <div className="contents">
        <div className="first-row">
          <div className="firstname">
            <input
              className="firstname-child"
              name="Firstname"
              type="text"
              required
            />
            <div className="first-name">First Name</div>
          </div>
          <div className="firstname">
            <input
              className="firstname-child"
              name="Lastname"
              type="text"
              required
            />
            <div className="last-name">Last Name</div>
          </div>
          <div className="email1">
            <input className="email-child" name="email" type="email" required />
            <div className="email2">Email</div>
          </div>
          </div>
          <div className="about-you">
          <div className="tell-us-more">Tell us more about you</div>
          <textarea
              className="content-child"
              name="content"
              type="text"
              required
            />
        </div>
        <button className="submit-form-button">
        <div className="submit">Submit</div>
        </button>
        </div>
          </form>
      <div className="anchor-contact-us" data-scroll-to="contactUsContainer" />
    </div>
  );
};

export default ContactUs;
